import React       from 'react';
import styled      from '@emotion/styled';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import { GiCheckedShield } from "react-icons/gi";


import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';
import Lightbox      from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import CallToAction      from '@interness/theme-sonora/src/components/CallToAction/CallToAction';


import { menu } from '../../data/service-menu';

const J = styled.p`
  text-align: justify;
`;

const EhrensilberPage = (props) => {
  const galleryImages = props.data.gallery;
  return (
    <>
      <SEO title={'Ehrensilber'}/>
      <Subnavigation menu={menu} menuTitle='Atelier Service'/>
      <HeaderImage>
        <Img fluid={props.data.headerImages.media[3].file.localFile.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer />
        <Heading icon={<GiCheckedShield/>}>Fertigung von Ehrensilber</Heading>
        <J>
          Es gibt immer etwas zu feiern – und wir fertigen die schmucken Stücke dazu. Für Schützenvereine entwerfen
          und erstellen wir zum Beispiel Königsketten, Ehrenplaketten und Königsschilde mit individuellen Motiven.
          Aber auch für andere Vereine, Institutionen oder Abiturjahrgänge sind wir kompetenter Ansprechpartner und
          entwerfen Orden sowie Ehren- oder Siegelringe als Einzelstücke oder Serien.
        </J>
        <J>
          Zur Herstellung der Stücke verwenden wir 925 Sterlingsilber, auf Wunsch auch vergoldet. Von den Skizzen
          nach
          Kundenwunsch über das Schmieden bis zur Gravur wird bei uns alles in meisterlicher Handarbeit ausgeführt.
        </J>
        <h3>Galerie</h3>
        <Lightbox images={galleryImages.images} thumbnails={galleryImages.thumbnails} fromApi/>
        <Spacer/>
      </Wrapper>
      <CallToAction/>
    </>
  )
};

export default EhrensilberPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "goldschmiede-headers"}) {
            ...MediaCollectionHeaderImages
        }
        gallery: directusMediaCollection(name: {eq: "ehrensilber"}) {
            ...LightboxGalleryGrid
        }
    }
`;
